<template>
 
  <div class="row"> 
    <div class="col-12 background back-jcc" >       
      <div class="row">
        <div class="col-12">
          <div class="blue-div">
            <div class="row">
              <div class="col-12">
                <div class="yellow-border-img-block">
                  <img src="/imgs/Main-Flashing-NFT.gif" alt="jczombie" />
                </div>
              </div>
              <div class="col-12 text-center">
                <h4 class="proximabold blue inline">SALE </h4><h4 class="proximabold white inline"> HAS</h4><h4 class="proximabold yellow inline"> CLOSED</h4>
                <template v-if="isDateValid">
                  <p class="white pt-2 mb-0 proximaregular">The Next Sale Will Be:</p>
                  <p class="white mb-0 proximaregular">{{ nextSaleDate }} <b class="grey bold-none mb-0">|</b> {{ nextSaleTime }} </p>
                </template>
              </div>
              <div class="col-12 pt-4 text-center" v-if="!submitted">
                <p class="f-10 white proximaregular">Sign-up to our mailing list to be notified when the next sale is about to go live.</p>
                <form class="input-group mx-auto mb-3 group-width" @submit.prevent="subscribe">
                  <input id="notify-group-btn" v-model="email" type="email" name="email" class="form-control" placeholder="Email Address" aria-label="Email Address" aria-describedby="button-notify">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary btn-custom-blue Museo700Italic" type="submit" id="button-notify" action="#">Be Notified</button>
                  </div>
                </form>
              </div>
              <div class="col-12 pt-4 text-center" v-else>
                <p class="f-10 white proximaregular">Thank you for signing up to our mailing list; You will be notified when the next sale is about to go live.</p>
              </div>
              <div class="col-12 pt-4 text-center">
                  <a href="https://discord.gg/hYE4ztdqjQ" rel="noopener" target="_blank"><img class="social" src="imgs/dc.svg" alt="discord" /></a>
              </div>
              <div class="col-12 pt-2 text-center font-p-mobile">
                  <p class="mb-1 inline yellow underl pointer proximaregular"><a href="https://discord.gg/hYE4ztdqjQ" rel="noopener" target="_blank">Join our Discord Channel</a></p> <p class="mb-1 inline white proximaregular">to stay informed about all <br> upcoming projects and developments</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   </div>

</template>

<script>

import { mapGetters } from "vuex";

const subscribe_url = 'https://jokercharlie.org/jcvd/subscribe'

export default {
  name: "InactiveSalePage",
  data() {
    return {
      nextSaleDate: "-----",
      nextSaleTime: "--:--",
      isDateValid: false,
      submitted: false,
      email: ""
    }
  },
  computed: {
    ...mapGetters("nft", ["getData", "isValid"]),
  },
  methods: {
    refresh() {
      if (!this.isValid) return            
      let data = this.getData                  
      let date = data.nextSaleStart
      if (date === null || data.nextPresaleStart < date) date = data.nextPresaleStart      
      if (date === null) return
      date = new Date(date)
      const Months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
      Number.prototype.zeroPad = Number.prototype.zeroPad || 
        function(base){
          var nr = this, len = (String(base).length - String(nr).length)+1;
        return len > 0? new Array(len).join('0')+nr : nr;
      };
      this.nextSaleDate = date.getUTCDate() + ' ' + Months[date.getUTCMonth()] + ' ' + date.getUTCFullYear()
      this.nextSaleTime = date.getUTCHours().zeroPad(10) + ':' + date.getUTCMinutes().zeroPad(10) + ' UTC'
      this.isDateValid  = true      
    },
    subscribe() {
      // post url
      if (this.email === '') return
      window.axios.get(`${subscribe_url}?email=${this.email}`).then((response)=>{
        console.log(response)
        this.submitted = true
      })
    }
  },
  watch: {
    isValid: function() { this.refresh() },
    getData: function() { this.refresh() }
  }
}



</script>