<template>

<header class="et-l et-l--header">
  <div class="et_builder_inner_content et_pb_gutters3">
    <div class="et_pb_section et_pb_section_0_tb_header main-header et_pb_sticky_module et_pb_with_background et_section_regular">
      <div class="et_pb_row et_pb_row_0_tb_header et_pb_row--with-menu">
        <div class="et_pb_column et_pb_column_2_5 et_pb_column_0_tb_header  et_pb_css_mix_blend_mode_passthrough">
          <div class="et_pb_module et_pb_image et_pb_image_0_tb_header">
            <a href="https://jokercharlieclub.com/home/"><span class="et_pb_image_wrap "><img src="https://jokercharlieclub.com/wp-content/uploads/2022/04/logo_latest.svg" alt="" title="logo_latest" class="wp-image-1710" /></span></a>
          </div>
        </div>
        <div class="et_pb_column et_pb_column_3_5 et_pb_column_1_tb_header  et_pb_css_mix_blend_mode_passthrough et-last-child et_pb_column--with-menu">
          <div class="et_pb_with_border et_pb_module et_pb_menu et_pb_menu_0_tb_header et_pb_bg_layout_light  et_pb_text_align_right et_dropdown_animation_fade et_pb_menu--without-logo et_pb_menu--style-left_aligned">
            <div class="et_pb_menu_inner_container clearfix">
              <div class="et_pb_menu__wrap">
                <div class="et_pb_menu__menu">
                  <nav class="et-menu-nav">
                    <ul id="menu-headernavbar" class="et-menu nav">
                      <li class="et_pb_menu_page_id-68 menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-68 current_page_item menu-item-1480"><a href="https://jokercharlieclub.com/home/" aria-current="page">Home</a></li>
                      <li class="et_pb_menu_page_id-391 menu-item menu-item-type-post_type menu-item-object-page menu-item-412"><a href="https://jokercharlieclub.com/creators/">Creators</a></li>
                      <li class="et_pb_menu_page_id-400 menu-item menu-item-type-post_type menu-item-object-page menu-item-411"><a href="https://jokercharlieclub.com/supporters/">Supporters</a></li>
                    </ul>
                  </nav>
              </div>
              <div class="et_mobile_nav_menu">
                <div class="mobile_nav closed">
                  <span class="mobile_menu_bar"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="et_pb_module et_pb_image et_pb_image_1_tb_header et_pb_image_sticky">
          <a href="https://t.me/jokercharlieclub" target="_blank"><span class="et_pb_image_wrap "><img src="https://jokercharlieclub.com/wp-content/uploads/2022/01/03_telegram.svg" alt="" title="03_telegram" class="wp-image-962" /></span></a>
        </div>
        <div class="et_pb_module et_pb_image et_pb_image_2_tb_header et_pb_image_sticky">
          <a href="https://twitter.com/jokercharlieNFT/" target="_blank"><span class="et_pb_image_wrap "><img src="https://jokercharlieclub.com/wp-content/uploads/2022/01/02_twitter.svg" alt="" title="02_twitter" class="wp-image-1293" /></span></a>
        </div>
        <div class="et_pb_module et_pb_image et_pb_image_3_tb_header et_pb_image_sticky">
          <a href="https://discord.com/invite/hYE4ztdqjQ" target="_blank"><span class="et_pb_image_wrap "><img src="https://jokercharlieclub.com/wp-content/uploads/2022/01/01_discord.svg" alt="" title="01_discord" class="wp-image-960" /></span></a>
        </div>
      </div>
    </div>
  </div>
  
  <div class="et_pb_section et_pb_section_1_tb_header mobile-header et_pb_sticky_module et_pb_with_background et_section_regular">
    <div class="et_pb_row et_pb_row_1_tb_header et_pb_row--with-menu">
      <div class="et_pb_column et_pb_column_4_4 et_pb_column_2_tb_header  et_pb_css_mix_blend_mode_passthrough et-last-child et_pb_column--with-menu">
        <div class="et_pb_module et_pb_menu et_pb_menu_1_tb_header et_pb_bg_layout_light  et_pb_text_align_right et_dropdown_animation_fade et_pb_menu--with-logo et_pb_menu--style-left_aligned">
          <div class="et_pb_menu_inner_container clearfix">
            <div class="et_pb_menu__logo-wrap">
              <div class="et_pb_menu__logo">
                <a href="https://jokercharlieclub.com/home/"><img src="https://jokercharlieclub.com/wp-content/uploads/2022/04/logo_latest.svg" alt="" class="wp-image-1710" /></a>
              </div>
            </div>
            <div class="et_pb_menu__wrap">
              <div class="et_pb_menu__menu">
                <nav class="et-menu-nav">
                  <ul id="menu-mobilemenu" class="et-menu nav">
                    <li id="menu-item-1504" class="et_pb_menu_page_id-68 menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-68 current_page_item menu-item-1504"><a href="https://jokercharlieclub.com/home/" aria-current="page">Home</a></li>
                    <li id="menu-item-410" class="et_pb_menu_page_id-391 menu-item menu-item-type-post_type menu-item-object-page menu-item-410"><a href="https://jokercharlieclub.com/creators/">Creators</a></li>
                    <li id="menu-item-409" class="et_pb_menu_page_id-400 menu-item menu-item-type-post_type menu-item-object-page menu-item-409"><a href="https://jokercharlieclub.com/supporters/">Supporters</a></li>
                  </ul>
                </nav>
              </div>
              <div class="et_mobile_nav_menu">
                <div class="mobile_nav closed">
                  <span class="mobile_menu_bar"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> </div>
</header>



</template>

<script>
import { mapGetters, mapActions } from "vuex";


export default {
  name: "Navbar",
  computed: {
    ...mapGetters("accounts", ["getActiveAccount", "isUserConnected", "getWeb3Modal"]),
  },
  mounted() {
    this.$nextTick(() => {
      window.$(window).scroll(function(){
        var sticky = window.$('#navbar'),
            scroll = window.$(window).scrollTop();
        if (scroll >= 100){
          sticky.addClass('add-fixed-nav');
        } 
        else{
          sticky.removeClass('add-fixed-nav');
        }
      });
    });
  },
  methods: {
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal"]),
    openMenu() {    
      if(window.$('#mobile-m').hasClass("display-not")){
        window.$('#mobile-m').removeClass("display-not");
        window.$('#mobile-m').addClass("show-mobile-nav");   
      }
      else{
        window.$('#mobile-m').addClass("display-not");
        window.$('#mobile-m').removeClass("show-mobile-nav");
      }
    }
  }
}
</script>
