<template>
<div class="row"> 
  <div class="col-12 background back-jcc" >      
    <div class="row">
      <div class="col-12"> 


        <!-- div class="blue-div2" v-show="getSaleState == 'close'">
          <div class="row">
            <InactiveSale />
          </div>
        </div -->

        <div class="blue-div" v-show="getSaleState != 'close'">
          <div class="row">

            <div class="col-12 mt-4">              

              <div class="text-center white pointer" v-if="isUserConnected" title="Disconnect" @click="disconnectWeb3Modal">              
                {{ getActiveAccount }}                
              </div>
              <div class="text-center white pointer" v-else title="Connect" @click="connectWeb3Modal">              
                CONNECT YOUR WALLET TO START MINTING
              </div>
            </div>

            <div class="col-12">
              <div class="yellow-border-img-block">
                <img src="/imgs/Main-Flashing-NFT.gif" alt="jczombie" @click="disconnectWeb3Modal" />
              </div>
            </div>

            <div class="col-12" v-show="!readyToMint">
                <div class="col-12 text-center">
                  <h4 class="proximabold white">JCC Genesis Voucher Mint</h4>                  
                  <template v-if="!isUserConnected">
                    <p class="yellow pt-2 mb-0 proximaregular">Please connect to the {{ getSupportChainName }} network.</p>
                  </template>
                  <template v-else>
                    <template v-if="getChainId != getSupportChainId">
                      <p class="yellow pt-2 mb-0 proximaregular">Please switch to the {{ getSupportChainName }} network.</p>                  
                    </template>
                    <template v-else>
                      <p class="yellow pt-2 mb-0 proximaregular">Connected to the {{ getChainName }} network.</p>
                    </template>
                  </template>
                </div>
                <div class="col-12 pt-4 text-center">
                  <div class="input-group mx-auto mb-3" style="width:80px;">
                      <button class="btn btn-custom-blue Museo700Italic" type="button" id="connect-btn" 
                              style="border-radius: 7px !important;" 
                              @click="connect" v-if="!isUserConnected"
                      >
                          Connect
                      </button>
                      <button class="btn btn-custom-blue Museo700Italic" type="button" id="switch-btn" 
                              style="border-radius: 7px !important;" 
                              @click="switchNetwork" v-else
                      >
                          Switch
                      </button>
                  </div>
                </div>
                <!-- div class="col-12 pt-1 text-center" v-show="getCurrentSaleEnd !== null">
                  <p class="white pt-1 pb-3 mb-0 proximaregular">Counting down to the close of sale</p>
                  <div class="flip-container">
                    <div id="myTick" class="tick" data-did-init="handleTickInit">              
                      <div data-repeat="true" data-layout="horizontal center fit" data-transform="preset(d, h, m, s) -> delay">                  
                        <div class="tick-group">                  
                          <div data-key="value" data-repeat="true" data-transform="pad(00) -> split -> delay">                 
                            <span data-view="flip"></span>                  
                          </div>                  
                          <span data-key="label" data-view="text" class="tick-label"></span>                  
                        </div>                  
                      </div>              
                    </div>
                 </div>                
               </div -->               
            </div>

            <div class="col-12" v-show="readyToMint && !canMint">

              <div class="col-12 text-center" v-if="!completed"> <!-- mint -->
                <div class="col-12 text-center">
                  <h4 class="proximabold white">JCC Genesis Voucher Mint</h4>                  
                  <p class="red pt-2 mb-0 proximaregular">{{ cannotMintReason }}</p>                                   
                  <p class="yellow pt-2 mb-0 proximaregular" v-if="fetching">{{ fetchMessage }}</p>                                   
                </div>
              </div>
              
            
            </div>


            <div class="col-12" v-show="readyToMint && canMint">

              <div class="col-12 text-center" v-if="!completed"> <!-- mint -->
                <div class="col-12 text-center">
                  <h4 class="proximabold white">JCC Genesis Voucher Mint</h4>                  
                  <p class="red pt-2 mb-0 proximaregular">{{voucherDetail}}</p>
                  <template v-if="minting">
                    <p class="yellow pt-2 mb-0 proximaregular">{{ mintMessage }}</p>    
                  </template>        
                  <template v-else>                
                    <p class="yellow pt-2 mb-0 proximaregular">{{ mintMessage }}</p>                 
                  </template>
                  <p class="yellow pt-2 mb-0 proximaregular" v-if="fetching">{{ fetchMessage }}</p>                                                     
                </div>

                <div class="col-12 pt-4 text-center mx-auto" style="width:240px;" >
                  <!-- input id="nft" v-model="amount" class="form-control custom-blk-control" type="number" value="1" min="1" :max="getMaxMintAmount" :disabled="minting"/ -->

                  <div class="input-group mx-auto pt-4 mb-3" style="width:60px;">
                    <button class="btn btn-custom-blue Museo700Italic" type="button" id="buy-btn" style="border-radius: 7px !important;" 
                            @click="onMint" :disabled="minting && getContract != null" >
                      <template v-if="!minting">
                        Mint
                      </template>
                      <template v-else>
                        <img src="/imgs/loading.gif" class="loader" style="border-radius: 7px !important;">                              
                      </template>
                    </button>                      
                  </div>                
                </div>
                <div class="col-12 text-center mx-auto" style="width:360px;" v-if="minting && txHash !== ''">                                
                  <p class="pt-3 mb-0 white hash-text hash-spacing">{{txHash}}</p>
                  <a :href="txHashURL" class="grey mt-0 hash-text2 f-10 pt-0 hash-spacing" target="_blank">TxHash View</a>
                </div>
              </div>                          
              <template  v-else> <!-- completed -->
                <div class="col-12 text-center">
                  <div class="col-12 text-center">
                    <h4 class="proximabold yellow inline">Congratulations </h4><h4 class="proximabold white inline"> on officialy becoming<br> a member of the </h4><h4 class="proximabold blue inline"> Joker Charlie Club</h4><h4 class="proximabold white inline">.</h4>
                    <p class="white pt-5 mb-0 proximaregular">Your Joker Charlie NFTs can be viewed <br> in your connected wallet.</p>                 
                  </div>
                </div>
                <div class="col-12 pt-4 text-center">
                  <div class="input-group mx-auto mb-3" style="width:140px;">                  
                    <button class="btn btn-custom-blue Museo700Italic" type="button" id="wallet-btn" style="border-radius: 7px !important;" @click="onView">
                      View
                    </button>                  
                    <button class="btn btn-custom-red Museo700Italic mt-4" type="button" id="wallet-btn" style="border-radius: 7px !important;" @click="disconnectWeb3Modal">
                      Mint Again
                    </button>                  
                  </div>
                </div>
              </template>

            </div>

          </div>
        </div>
      </div>
    </div>
   </div>
</div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { ethers } from "ethers";

// import InactiveSale from '@/pages/components/InactiveSaleComponent'

// let voucherurl = 'https://jokercharlie.org/vd'

const startAnimation = function() {
  // Globals
  var random = Math.random
    , cos = Math.cos
    , sin = Math.sin
    , PI = Math.PI
    , PI2 = PI * 2
    , timer = undefined
    , frame = undefined
    , confetti = [];

  var spread = 40
    , sizeMin = 3
    , sizeMax = 12 - sizeMin
    , eccentricity = 10
    , deviation = 100
    , dxThetaMin = -.1
    , dxThetaMax = -dxThetaMin - dxThetaMin
    , dyMin = .13
    , dyMax = .18
    , dThetaMin = .4
    , dThetaMax = .7 - dThetaMin;
  
  var colorThemes = [
    function() {
      return color(200 * random()|0, 200 * random()|0, 200 * random()|0);
    }, function() {
      var black = 200 * random()|0; return color(200, black, black);
    }, function() {
      var black = 200 * random()|0; return color(black, 200, black);
    }, function() {
      var black = 200 * random()|0; return color(black, black, 200);
    }, function() {
      return color(200, 100, 200 * random()|0);
    }, function() {
      return color(200 * random()|0, 200, 200);
    }, function() {
      var black = 256 * random()|0; return color(black, black, black);
    }, function() {
      return colorThemes[random() < .5 ? 1 : 2]();
    }, function() {
      return colorThemes[random() < .5 ? 3 : 5]();
    }, function() {
      return colorThemes[random() < .5 ? 2 : 4]();
    }
  ];
  function color(r, g, b) {
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }

  // Cosine interpolation
  function interpolation(a, b, t) {
    return (1-cos(PI*t))/2 * (b-a) + a;
  }

  // Create a 1D Maximal Poisson Disc over [0, 1]
  var radius = 1/eccentricity, radius2 = radius+radius;
  function createPoisson() {
    // domain is the set of points which are still available to pick from
    // D = union{ [d_i, d_i+1] | i is even }
    var domain = [radius, 1-radius], measure = 1-radius2, spline = [0, 1];
    while (measure) {
      var dart = measure * random(), i, l, interval, a, b, c, d;

      // Find where dart lies
      for (i = 0, l = domain.length, measure = 0; i < l; i += 2) {
        a = domain[i], b = domain[i+1], interval = b-a;
        if (dart < measure+interval) {
          spline.push(dart += a-measure);
          break;
        }
        measure += interval;
      }
      c = dart-radius, d = dart+radius;

      // Update the domain
      for (i = domain.length-1; i > 0; i -= 2) {
        l = i-1, a = domain[l], b = domain[i];
        // c---d          c---d  Do nothing
        //   c-----d  c-----d    Move interior
        //   c--------------d    Delete interval
        //         c--d          Split interval
        //       a------b
        if (a >= c && a < d)
          if (b > d) domain[l] = d; // Move interior (Left case)
          else domain.splice(l, 2); // Delete interval
        else if (a < c && b > c)
          if (b <= d) domain[i] = c; // Move interior (Right case)
          else domain.splice(i, 0, c, d); // Split interval
      }

      // Re-measure the domain
      for (i = 0, l = domain.length, measure = 0; i < l; i += 2)
        measure += domain[i+1]-domain[i];
    }

    return spline.sort();
  }

  // Create the overarching container
  var container = document.createElement('div');
  container.style.position = 'fixed';
  container.style.top      = '0';
  container.style.left     = '0';
  container.style.width    = '100%';
  container.style.height   = '0';
  container.style.overflow = 'visible';
  container.style.zIndex   = '9999';

  // Confetto constructor
  function Confetto(theme) {
    this.frame = 0;
    this.outer = document.createElement('div');
    this.inner = document.createElement('div');
    this.outer.appendChild(this.inner);

    var outerStyle = this.outer.style, innerStyle = this.inner.style;
    outerStyle.position = 'absolute';
    outerStyle.width  = (sizeMin + sizeMax * random()) + 'px';
    outerStyle.height = (sizeMin + sizeMax * random()) + 'px';
    innerStyle.width  = '100%';
    innerStyle.height = '100%';
    innerStyle.backgroundColor = theme();

    outerStyle.perspective = '50px';
    outerStyle.transform = 'rotate(' + (360 * random()) + 'deg)';
    this.axis = 'rotate3D(' +
      cos(360 * random()) + ',' +
      cos(360 * random()) + ',0,';
    this.theta = 360 * random();
    this.dTheta = dThetaMin + dThetaMax * random();
    innerStyle.transform = this.axis + this.theta + 'deg)';

    this.x = window.innerWidth * random();
    this.y = -deviation;
    this.dx = sin(dxThetaMin + dxThetaMax * random());
    this.dy = dyMin + dyMax * random();
    outerStyle.left = this.x + 'px';
    outerStyle.top  = this.y + 'px';

    // Create the periodic spline
    this.splineX = createPoisson();
    this.splineY = [];
    for (var i = 1, l = this.splineX.length-1; i < l; ++i)
      this.splineY[i] = deviation * random();
    this.splineY[0] = this.splineY[l] = deviation * random();

    this.update = function(height, delta) {
      this.frame += delta;
      this.x += this.dx * delta;
      this.y += this.dy * delta;
      this.theta += this.dTheta * delta;

      // Compute spline and convert to polar
      var phi = this.frame % 7777 / 7777, i = 0, j = 1;
      while (phi >= this.splineX[j]) i = j++;
      var rho = interpolation(
        this.splineY[i],
        this.splineY[j],
        (phi-this.splineX[i]) / (this.splineX[j]-this.splineX[i])
      );
      phi *= PI2;

      outerStyle.left = this.x + rho * cos(phi) + 'px';
      outerStyle.top  = this.y + rho * sin(phi) + 'px';
      innerStyle.transform = this.axis + this.theta + 'deg)';
      return this.y > height+deviation;
    };
  }

  function poof() {
    if (!frame) {
      // Append the container
      document.body.appendChild(container);

      // Add confetti
      var theme = colorThemes[0];                
      (function addConfetto() {
        var confetto = new Confetto(theme);
        confetti.push(confetto);
        container.appendChild(confetto.outer);
        timer = setTimeout(addConfetto, spread * random());
      })(0);

      // Start the loop
      var prev = undefined;
      requestAnimationFrame(function loop(timestamp) {
        var delta = prev ? timestamp - prev : 0;
        prev = timestamp;
        var height = window.innerHeight;

        for (var i = confetti.length-1; i >= 0; --i) {
          if (confetti[i].update(height, delta)) {
            container.removeChild(confetti[i].outer);
            confetti.splice(i, 1);
          }
        }

        if (timer || confetti.length)
          return frame = requestAnimationFrame(loop);

        // Cleanup
        document.body.removeChild(container);
        frame = undefined;
      });
    }
  }
  poof();        
}

window.myTick = null

export default {
    name: "InactiveSalePage",
    data() {
        return {
            amount: 1,
            readyToMint: false,
            canMint: false,
            cannotMintReason: "",
            minting: false,
            voucherDetail: "",
            fetching: false,
            fetchMessage: "",
            mintMode: 'close',
            completed: false,
            mintMessage: 'Click buy to mint your NFT',
            txHash: '',
            txHashURL: '',
            init: false,
            mintInformation: null,
        }
    },
    components: {
        // InactiveSale
    },
    methods: {
        ...mapActions("accounts",["connectWeb3Modal","disconnectWeb3Modal"]),
        ...mapActions("nft",["initContract","fetchVouchersData","fetchContractData"]),
        async onPublicMint() {
          this.txHash = ''
          let contract = this.getContract
          if (contract === null) { this.initContract() }
          contract = this.getContract
          // console.log(contract)
          if (contract === null) {
            this.initContract()
            alert("Contract not initialise")
            return
          }
          let amount = this.amount;
          let canMint = await contract.isPublicMintActive();
          self.mintMessage = "Checking sale status...";
          if (!canMint) {
              this.mintMessage = "Sale is not active...";
              return
          }   
                  
          this.mintMessage = "Fetching Joker Price...";
          let price = await contract.publicMintPrice();
          let value = price.mul(amount);      
          this.mintMessage = "Sending instruction for signing...";

          this.minting = true                              
          try {
            const tx = await contract.publicMint(amount, { value: value });            
            this.txHash = tx.hash.substring(0,10) + '...' + tx.hash.substring(tx.hash.length-6)
            this.txHashURL = `https://rinkeby.etherscan.io/tx/${tx.hash}`
            // console.log(tx); // tx.hash
            this.mintMessage = `Minting in progress`
            const receipt = await tx.wait();   
            console.log(receipt); // 
            this.mintMessage = "Minting complete";
            this.onComplete()
          } catch (e) {
            console.log(e)
            if (e.code === 4001) {              
              this.mintMessage = `${e.message}`
            } else {
              console.log(e.message)
              if (e.message.search('insufficient funds') >= 0) {
                this.mintMessage = `Insufficient funds`
              } else {
                this.mintMessage = `${e.message}`
              }
            }
          }
          this.minting = false          
        },
        async onPrivateMint() {
          this.txHash = ''
          let contract = this.getContract
          if (contract === null) { this.initContract() }
          contract = this.getContract
          // console.log(contract)
          if (contract === null) {
            this.initContract()
            alert("Contract not initialise")
            return
          }
          let amount = this.amount;
          let canMint = await contract.isPrivateMintActive();
          self.mintMessage = "Checking sale status...";
          if (!canMint) {
              this.mintMessage = "Sale is not active...";
              return
          }   
                  
          this.mintMessage = "Fetching Joker Price...";
          let price = await contract.privateMintPrice();
          let value = price.mul(amount);      
          this.mintMessage = "Sending instruction for signing...";

          this.minting = true                              
          try {
            let voucher = this.whitelistVoucher
            const tx = await contract.privateMint(amount, voucher.id, voucher.amount, voucher.proof, { value: value });            
            this.txHash = tx.hash.substring(0,10) + '...' + tx.hash.substring(tx.hash.length-6)
            this.txHashURL = `https://rinkeby.etherscan.io/tx/${tx.hash}`
            console.log(tx); // tx.hash
            this.mintMessage = `Minting in progress`
            const receipt = await tx.wait();   
            console.log(receipt); // 
            this.mintMessage = "Minting complete";
            this.onComplete()
          } catch (e) {
            console.log(e)
            if (e.code === 4001) {              
              this.mintMessage = `${e.message}`
            } else {
              console.log(e.message)
              if (e.message.search('insufficient funds') >= 0) {
                this.mintMessage = `Insufficient funds`
              } else {
                this.mintMessage = `${e.message}`
              }
            }
          }
          this.minting = false          
        },
        async onVoucherMint() {
          this.txHash = ''
          let contract = this.getContract
          if (contract === null) { this.initContract() }
          contract = this.getContract
          // console.log(contract)
          if (contract === null) {
            this.initContract()
            alert("Contract not initialise")
            return
          }
          let amount = this.voucher.amount;
          self.mintMessage = "Checking sale status...";
          let canMint = await contract.isVoucherMintActive();          
          if (!canMint) {
              this.mintMessage = "Sale is not active...";
              return
          }   
                  
          console.log(ethers)
          console.log(this.voucher.price)
          let price = ethers.BigNumber.from(String(this.voucher.price))
          let value = price.mul(amount);      
          this.mintMessage = "Sending instruction for signing...";

          console.log(price)
          console.log(value)

          this.minting = true                              
          try {
            let voucher = this.voucher
            const tx = await contract.voucherMint(amount, voucher.id, voucher.amount, ethers.BigNumber.from(String(voucher.price)), voucher.proof, { value: value });            
            this.txHash = tx.hash.substring(0,10) + '...' + tx.hash.substring(tx.hash.length-6)
            this.txHashURL = `https://rinkeby.etherscan.io/tx/${tx.hash}`
            console.log(tx); // tx.hash
            this.mintMessage = `Minting in progress`
            const receipt = await tx.wait();   
            console.log(receipt); // 
            this.mintMessage = "Minting complete";
            this.onComplete()
          } catch (e) {
            console.log(e)
            if (e.code === 4001) {              
              this.mintMessage = `${e.message}`
            } else {
              console.log(e.message)
              if (e.message.search('insufficient funds') >= 0) {
                this.mintMessage = `Insufficient funds`
              } else {
                this.mintMessage = `${e.message}`
              }
            }
          }
          this.minting = false          
        },
        async onMint() {
            if (this.mintMode == 'public') this.onPublicMint()
            if (this.mintMode == 'private') this.onPrivateMint()
            if (this.mintMode == 'voucher') this.onVoucherMint()
        },
        onComplete() {       
            this.completed = true
            startAnimation() 
        },
        onView() {     
          window.open(this.txHashURL,"_blank")
          setTimeout(function(){
             window.open(`https://testnets.opensea.io/${this.getActiveAccount}`,"w0",'scrollbars=1,menubar=0,resizable=1,width=850,height=500');           
          }, 1000)
        },
        connect() {            
            this.connectWeb3Modal()
        },
        async switchNetwork() {       
            const currentProvider = this.getWeb3Provider
            const networkId = '0x'+ this.getSupportChainId.toString(16).toUpperCase()            
            try {
                await currentProvider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: networkId }]
                });
            } catch (error) {
              alert(error.message);
            }
        },
        async computeReadyToMint() {                        
            let readyToMint = this.isUserConnected && (this.getChainId == this.getSupportChainId)                        
            if (!this.readyToMint && readyToMint) {
                // this function should be call only once when readyToMint switch from false to true
                this.fetching = true
                let oldCursor = document.body.style.cursor
                document.body.style.cursor = 'wait';
                this.readyToMint = readyToMint

                this.fetchMessage = "Fetching mint information..."
                await this.fetchContractData()
                if (this.isVoucherMintActive) {                
                    this.fetchMessage = "Fetching voucher information..."
                    await this.fetchVouchersData(this.getActiveAccount)
                }
                this.fetching = false
                document.body.style.cursor = oldCursor
                // determine whether we can mint or not                
                this.mintMode = 'idle'                
                if (this.isVoucherMintActive) {                
                    if (this.voucher !== null) {
                        this.canMint = true            
                        if (this.voucher.price == 0.) {
                          this.voucherDetail = `You can mint ${this.voucher.amount} JCC Genesis`
                        } else {
                          this.voucherDetail = `You can mint ${this.voucher.amount} JCC Genesis for ${this.voucher.amount * this.voucher.price / 1e18} ETH`
                        }
                        this.mintMode = 'voucher'
                    } else {
                        this.canMint = false
                        this.cannotMintReason = 'Sorry you have no voucher to redeem'                        
                    }
                } else {
                    this.canMint = false
                    this.cannotMintReason = 'Voucher mint is closed at the momment. Please come back again later'                    
                }
                // console.log(this.voucher)
            }
        }
    },
    created() {        
        this.$nextTick(() => {          
            require("bootstrap-input-spinner")
            window.$("input[type='number']").inputSpinner();         
            this.amount = 1          
            window.myTickHandler = this                      
            const handleTickInit = function(tick) {
                window.myTick = tick
                const delayInit = function() {
                    if (!window.myTickHandler.isValid) {
                        setTimeout(delayInit, 100)                        
                    } else {                        
                        if (window.myTickHandler.getCurrentSaleEnd === null) return 
                        window.Tick.count.down(window.myTickHandler.getCurrentSaleEnd).onupdate = function(value) {           
                            tick.value = value;     
                        }
                    }
                }
                delayInit()
            }
            window.handleTickInit = handleTickInit
            this.readyToMint = this.isUserConnected && (this.getChainId == this.getSupportChainId)
        })
    },    
    computed: {
        ...mapGetters("nft", ["isValid",  "getSupportChainId", "getSupportChainName", "getContract", "getMintPrice", "getMaxMintAmount", "getSaleState",
                      // "getSalePrice",  "getSaleState", "getCurrentSaleEnd", "getCurrentSaleEndUTC", "getSalePrice", "getMaxSaleAmount",                      "                      
                      "isPublicMintActive", "isPrivateMintActive", "isVoucherMintActive", "maxPublicMintAmount","maxPrivateMintAmount","whitelistVoucher","voucher"]),

        ...mapGetters("accounts", ["isUserConnected", "getChainName", "getChainId", "getWeb3Provider", "getActiveAccount" ])
    },
    watch: {
      getWeb3Provider: function() { this.initContract() },
      isUserConnected: function() { this.computeReadyToMint()  },
      getChainId: function() { this.computeReadyToMint() },
      getSupportChainId: function() { this.computeReadyToMint()  },
    }
}    

</script>


