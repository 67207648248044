<template>
  <div class="black-background">

    <Navbar />

    <div class="container-fluid">

      <keep-alive>
        <router-view />
      </keep-alive>

      <Footer />

    </div>

  </div>
</template>

<script>

import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  methods: {
    ...mapActions("nft", ["fetchHttpData"]),
    ...mapActions("accounts", ["initWeb3Modal"])
  },
  async created() {
    await this.fetchHttpData()
    this.initWeb3Modal()
    // this.$store.dispatch("accounts/initWeb3Modal");
    // this.$store.dispatch("accounts/ethereumListener");
  },
  mounted() {
    
  }
}
</script>
