import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
// import BurnerConnectProvider from "@burner-wallet/burner-connect-provider";
import { ethers } from "ethers";
// import addresses from "@/contracts/addresses.json";
import chains from "@/contracts/chains.json";

// import WalletConnectProvider from '@walletconnect/web3-provider'
// export const defaultProvider = new ethers.providers.InfuraProvider(null, process.env.VUE_APP_INFURA_ID);

const providerOptions = {

  
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "86c7d1f5334e4ffa9ddd370f33f26dd8" // required
    }
  }
  /*
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.VUE_APP_INFURA_ID,
      rpc: {
        56: 'https://bsc-dataseed.binance.org/',
      },
    }
  },  
  burnerconnect: {
    package: BurnerConnectProvider,
    options: {
      defaultNetwork: "100",
    },
  },
  */
};

export const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: false, // true,
  providerOptions,
});

export const ethersProvider = null;
export const web3Provider = null;

// initial state
const state = () => ({
  isInitialized: false,
  isConnected: false,
  activeAccount: null,
  activeBalance: 0,
  chainId: null,
  chainData: null,
  chainName: null,
  chainSupported: true,
  error: null,
  web3Provider: null,
  ethersProvider: null,
});

// getters
const getters = {
  isInitialized(state) {
    return state.isInitialized;
  },
  isUserConnected(state) {
    return state.isConnected;
  },
  getActiveAccount(state) {
    return state.activeAccount;
  },
  getActiveBalanceWei(state) {
    return state.activeBalance;
  },
  getActiveBalanceEth(state) {
    return ethers.utils.formatEther(state.activeBalance);
  },
  getChainId(state) {
    return state.chainId;
  },
  getChainName(state) {
    return state.chainName
  },
  getChainData(state) {
    return state.chainData;
  },
  getChainSupported(state) {
    return state.chainSupported;
  },
  getWeb3Provider(state) {
    return state.web3Provider;
  },
  getEthersProvider(state) {
    return state.ethersProvider;
  }
};

// actions
const actions = {
  async connectWeb3Modal({ commit, dispatch, getters }) {
    const web3Provider = await web3Modal.connect();

    web3Provider.on("chainChanged", (chainId) => {
      commit("setChainData", chainId);
      actions.fetchActiveBalance({ commit, getters });
      window.location.reload();
    });

    web3Provider.on("accountsChanged", async (accounts) => {
      if (accounts.length === 0) {
        dispatch("disconnect");
      } else {
        const signer = ethersProvider.getSigner(accounts[0]);
        const network = await ethersProvider.getNetwork();        
        commit("setActiveAccount", await signer.getAddress());
        commit("setChainData", network.chainId);
        commit("setIsConnected", true);                

        console.log(`Account change ${accounts[0]}`)
        actions.fetchActiveBalance({ commit, getters });
      }
    });

    web3Provider.on("connect", (/*connectInfo*/) => {
      // TODO
    });

    web3Provider.on("disconnect", async (error) => {
      console.error(error);
      dispatch("disconnect");
    });

    const ethersProvider = new ethers.providers.Web3Provider(web3Provider);
    const signer = ethersProvider.getSigner();
    const network = await ethersProvider.getNetwork();
    const address = await signer.getAddress();

    commit("setWeb3Provider", web3Provider);
    commit("setEthersProvider", ethersProvider);
    commit("setActiveAccount", address);
    commit("setChainData", network.chainId);
    commit("setIsConnected", true);
    commit("setIsInitialized", true);
    actions.fetchActiveBalance({ commit, getters });
  },

  disconnectWeb3Modal({ commit }) {
    web3Modal.clearCachedProvider();
    // localStorage.removeItem("walletconnect");
    commit("setWeb3Provider", null);
    commit("setEthersProvider", null);
    commit("setActiveAccount", "");
    commit("setChainData", null);
    commit("setIsConnected", false);
    commit("setActiveBalance", 0);
    commit("setError", "");

    window.location.href = "../";
  },

  initWeb3Modal({ commit, dispatch }) {
    if (web3Modal.cachedProvider) {
      dispatch("connectWeb3Modal");
    } else {
      commit("setIsInitialized", true);
    }
  },

  async fetchActiveBalance({ commit, getters }) {
    const ethersProvider = new ethers.providers.Web3Provider(
      getters.getWeb3Provider
    );
    const balance = await ethersProvider.getBalance(getters.getActiveAccount);
    commit("setActiveBalance", balance);
  },
};

// mutations
const mutations = {
  setWeb3Provider: function (state, value) {
    state.web3Provider = value;
  },
  setEthersProvider: function (state, value) {
    state.ethersProvider = value;
  },
  setIsInitialized: function (state, value) {
    state.isInitialized = value;
  },
  setIsConnected: function (state, value) {
    state.isConnected = value;
  },
  setError: function (state, value) {
    state.error = value;
  },
  setActiveAccount: function (state, value) {
    state.activeAccount = value;
  },
  setActiveBalance: function (state, value) {
    state.activeBalance = value;
  },
  setChainData: function (state, value) {
    const id = parseInt(value);
    const defaultData = {
      name: id === 1337 ? "Localhost" : "Unknown",
      chainId: id,
      networkId: id,
    };
    let chainData = defaultData;
    state.chainId = id;
    chainData = chains.filter((c) => c.chainId === value)[0];
    state.chainData = chainData || defaultData;
    state.chainName = state.chainData.name        
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};


/*

import Web3Modal from "web3modal";
import { ethers } from "ethers";
// import BurnerConnectProvider from "@burner-wallet/burner-connect-provider";
// import Authereum from "authereum";

const state = {
  activeAccount: null,
  activeBalance: 0,
  chainId: null,
  chainName: null,
  providerEthers: null, // this is "provider" for Ethers.js
  isConnected: false,
  providerW3m: null, // this is "provider" from Web3Modal
  web3Modal: null
};

const getters = {
  getActiveAccount(state) {
    return state.activeAccount;
  },
  getActiveBalanceWei(state) {
    return state.activeBalance;
  },
  getActiveBalanceEth(state) {
    return ethers.utils.formatEther(state.activeBalance);
  },
  getChainId(state) {
    return state.chainId;
  },
  getChainName(state) {
    return state.chainName;
  },
  getProviderEthers(state) {
    return state.providerEthers;
  },
  getWeb3Modal(state) {
    return state.web3Modal;
  },
  isUserConnected(state) {
    return state.isConnected;
  }
};

const actions = {

  async initWeb3Modal({ commit }) {
    const providerOptions = {
      // MetaMask is enabled by default
      // Find other providers here: https://github.com/Web3Modal/web3modal/tree/master/docs/providers
      // burnerconnect: {
      //  package: BurnerConnectProvider // required
      //},
      //authereum: {
      //  package: Authereum // required
      //}
    };
    
    const w3mObject = new Web3Modal({
      cacheProvider: false, // true, // optional
      providerOptions // required
    });

    // This will get deprecated soon. Setting it to false removes a warning from the console.
    window.ethereum.autoRefreshOnNetworkChange = true

    // if the user is flagged as already connected, automatically connect back to Web3Modal
    if (localStorage.getItem('isConnected') === "true") {
      let providerW3m = await w3mObject.connect();
      commit("setIsConnected", true);

      commit("setActiveAccount", window.ethereum.selectedAddress);
      commit("setChainData", window.ethereum.chainId);
      commit("setEthersProvider", providerW3m);
      actions.fetchActiveBalance({ commit });
    }

    commit("setWeb3ModalInstance", w3mObject);
  },

  async connectWeb3Modal({ commit }) {
    let providerW3m = await state.web3Modal.connect();

    provider.on("connect", async (info) => {
      let chainId = parseInt(info.chainId)
      commit('setChainId', chainId)
      console.log("connect", info)
  });

  provider.on("accountsChanged", async (accounts) => {
      if (accounts.length > 0) {
          commit('setAccount', accounts[0])
      } else {
          await dispatch('resetApp')
      }
      console.log("accountsChanged")
  });
  provider.on("chainChanged", async (chainId) => {
      chainId = parseInt(chainId)
      commit('setChainId', chainId)
      console.log("chainChanged", chainId)
  });

    

    commit("setIsConnected", true);

    commit("setActiveAccount", window.ethereum.selectedAddress);
    commit("setChainData", window.ethereum.chainId);
    commit("setEthersProvider", providerW3m);
    actions.fetchActiveBalance({ commit });
  },

  async disconnectWeb3Modal({ commit }) {
    commit("disconnectWallet");
    commit("setIsConnected", false);
  },

  async ethereumListener({ commit }) {

    window.ethereum.on('accountsChanged', (accounts) => {
      if (state.isConnected) {
        commit("setActiveAccount", accounts[0]);
        commit("setEthersProvider", state.providerW3m);
        actions.fetchActiveBalance({ commit });
      }
    });

    window.ethereum.on('chainChanged', (chainId) => {
      alert("Chain Change")
      commit("setChainData", chainId);
      commit("setEthersProvider", state.providerW3m);
      actions.fetchActiveBalance({ commit });
    });

  },

  async fetchActiveBalance({ commit }) {
    let balance = await state.providerEthers.getBalance(state.activeAccount);
    commit("setActiveBalance", balance);
  }
  
};

const mutations = {

  async disconnectWallet(state) {
    state.activeAccount = null;
    state.activeBalance = 0;
    state.providerEthers = null;
    if (state.providerW3m.close && state.providerW3m !== null) {
      await state.providerW3m.close();
    }
    state.providerW3m = null;
    await state.web3Modal.clearCachedProvider();

    window.location.href = '../'; // redirect to the Main page
  },

  setActiveAccount(state, selectedAddress) {
    state.activeAccount = selectedAddress;
  },

  setActiveBalance(state, balance) {
    state.activeBalance = balance;
  },

  setChainData(state, chainId) {
    state.chainId = chainId;

    switch(chainId) {
      case "0x1":
        state.chainName = "Mainnet";
        break;
      case "0x2a":
        state.chainName = "Kovan";
        break;
      case "0x3":
        state.chainName = "Ropsten";
        break;
      case "0x4":
        state.chainName = "Rinkeby";
        break;
      case "0x5":
        state.chainName = "Goerli";
        break;
      case "0x539": // 1337 (often used on localhost)
      case "0x1691": // 5777 (default in Ganache)
      default:
        state.chainName = "Localhost";
        break;
    }
  },

  async setEthersProvider(state, providerW3m) {
    state.providerW3m = providerW3m;
    state.providerEthers = new ethers.providers.Web3Provider(providerW3m);
  },

  setIsConnected(state, isConnected) {
    state.isConnected = isConnected;
    // add to persistent storage so that the user can be logged back in when revisiting website
    localStorage.setItem('isConnected', isConnected);
  },

  setWeb3ModalInstance(state, w3mObject) {
    state.web3Modal = w3mObject;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
*/